import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Container, Col, Row, Button } from "reactstrap"
import Img from "gatsby-image"
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const BigHeader = ({ title, description, ctaText, ctaTo }) => {
  const data = useStaticQuery(graphql`
    query GetImageBackground {
      file(relativePath: { eq: "background-index.jpg" }) {
        id
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="header-1">
        <div className="page-header header-filter">
          <div className="page-header-image">
            <Img
              fluid={data.file.childImageSharp.fluid}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </div>
          <Container>
            <Row>
              <Col className="ml-auto text-center text-md-right" md="7">
                {title && (
                  <h1
                    className="title"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                )}

                {description && (
                  <h4
                    className="description"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}
                <br></br>
                <div className="buttons">
                  <Button
                    className="btn-icon btn-neutral btn-sea-hover-color"
                    color="link"
                    href="https://www.facebook.com/Morska-5-pokojeapartamenty-102339041559896/"
                    size="lg"
                  >
                    <i>
                      <FontAwesomeIcon icon={faFacebookSquare} />
                    </i>
                  </Button>
                  <Button
                    className="btn-icon btn-neutral btn-sea-hover-color mr-3"
                    color="link"
                    href="https://www.instagram.com/morska5/"
                    size="lg"
                  >
                    <i>
                      <FontAwesomeIcon icon={faInstagram} />
                    </i>
                  </Button>
                  <Link
                    className="mr-3 btn btn-sea btn-lg"
                    color="sea"
                    to={ctaTo}
                    size="lg"
                  >
                    {ctaText}
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default BigHeader
