import React from "react"
// react plugin used to create google maps
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps"
// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"
import { useMediaQuery } from "react-responsive"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
// core components

const MapWrapper = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: 54.09615, lng: 15.07599 }}
      defaultOptions={{
        scrollwheel: false,
        styles: [
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [{ color: "#ffffff" }, { lightness: 17 }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }, { lightness: 18 }],
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }, { lightness: 16 }],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#dedede" }, { lightness: 21 }],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              { visibility: "on" },
              { color: "#ffffff" },
              { lightness: 16 },
            ],
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              { saturation: 36 },
              { color: "#333333" },
              { lightness: 40 },
            ],
          },
          { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
          },
          {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [{ color: "#fefefe" }, { lightness: 20 }],
          },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
          },
        ],
      }}
    >
      <Marker position={{ lat: 54.09615, lng: 15.07599 }} />
    </GoogleMap>
  ))
)

function Map(props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 992px)",
  })

  const map = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "map.png" }) {
        id
        childImageSharp {
          fluid {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="big-map" id="contactUsMap">
        {isDesktopOrLaptop && (
          <>
            <MapWrapper
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyDAt4tM18Vd0TTA_QrBc_QqlB8NxeA0jho&v=3.exp&libraries=geometry,drawing,places&language=${props.lang}`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </>
        )}

        <div className="contactBox">
          <Container>
            <Row>
              <Col md="12" lg="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <Img
                        fluid={map.file.childImageSharp.fluid}
                        style={{
                          minHeight: "100px",
                          borderRadius: "50%",
                        }}
                        className="img img-raised"
                      />
                    </a>
                  </div>
                  <CardBody>
                    <p className="card-description">{props.description}</p>
                  </CardBody>
                  <div className="icon icon-sea">
                    <i className="fas fa-map-marker-alt"></i>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h4">Morska 5, Niechorze</CardTitle>
                    <p className="category">
                      <a
                        href="tel:0048791452000"
                        title="Kliknij i zadzwoń do nas."
                        className="color-sea"
                      >
                        +48 791 452 000
                      </a>
                      <br />
                      <a
                        href="mailto:rezerwacje@morska5.pl"
                        title="Kliknij i napisz do nas."
                        className="color-sea"
                      >
                        rezerwacje@morska5.pl
                      </a>
                    </p>
                    <p className="category">
                      <a
                        target="_BLANK"
                        rel="noopener noreferrer"
                        href="https://www.google.pl/maps/dir//Morska+5,+72-350+Niechorze/@52.274754,17.6774396,7.25z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4700767dfeeb7c8f:0x5f10adbbdde20aa0!2m2!1d15.0758085!2d54.0958868!3e0?hl=pl"
                        className="btn btn-sea"
                      >
                        {props.ctaText}
                      </a>
                    </p>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default Map
